<template>
  <v-container fluid>
    <v-row>
      <v-col col="12">
        <PrintListForm />
      </v-col>
    </v-row>
    <v-row>
      <v-col col="12">
        <OldPrintLists />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PrintListForm from "@/components/locations/PrintListForm";
import OldPrintLists from "@/components/locations/OldPrintLists";

export default {
  name: "PrintList",
  components: { PrintListForm, OldPrintLists },
};
</script>
