<template>
  <v-card tile :loading="loading" class="mx-auto" width="500px">
    <v-card-title dense flat class="header">
      <v-toolbar-title>{{ $t("printlist.old.title") }}</v-toolbar-title>
    </v-card-title>

    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="items"
        class="row-pointer"
        @click:row="downloadListById"
      >
        <template v-slot:item.name="{ item }">
          <v-list-item-icon>
            <v-icon>mdi-download</v-icon>{{ item.name }}
          </v-list-item-icon>
        </template>

        <template v-slot:item.date="{ item }">
          {{ new Date(item.printedDt).toLocaleString("nl-NL") }}
        </template>

        <template v-slot:item.total="{ item }">
          <v-layout justify-end>
            {{ item.total }}
          </v-layout>
        </template>
      </v-data-table>
    </v-card-text>

    <v-snackbar
      v-model="success"
      :value="true"
      bottom
      tile
      color="success accent-2"
    >
      {{ $t("printlist.snackbar.download") }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="success = false">
          {{ $t("printlist.snackbar.close") }}
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar v-model="failed" :value="true" bottom tile color="red accent-2">
      {{ $t("printlist.snackbar.failed") }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="failed = false">
          {{ $t("printlist.snackbar.close") }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { DownloadService } from "@/services/DownloadService";

export default {
  name: "PrintListForm",
  created() {
    if (this.location !== null) {
      console.log(this.location);
      this.setLocation(location);
      this.fetchItems(true);
    }
  },
  data: (vm) => ({
    headers: [
      {
        text: vm.$t("printlist.table.headers.name"),
        sortable: false,
        value: "name",
        width: "40%",
      },
      {
        text: vm.$t("printlist.table.headers.date"),
        sortable: false,
        value: "date",
        width: "30%",
      },
      {
        text: vm.$t("printlist.table.headers.total"),
        sortable: false,
        value: "total",
        class: "text-end",
        width: "20%",
      },
    ],
    success: false,
    failed: false,
  }),
  computed: {
    ...mapState("printListsOverview", ["items", "loading"]),
    ...mapState("locationsOverview", ["location"]),
  },
  methods: {
    ...mapActions("printListsOverview", ["fetchItems", "setLocation"]),
    downloadListById: function (item) {
      if (item.id !== null) {
        DownloadService.downloadListById(item.id)
          .then((response) => {
            console.log(response);
            let today = new Date();
            let dd = String(today.getDate()).padStart(2, "0");
            let mm = String(today.getMonth() + 1).padStart(2, "0");
            today =
              dd +
              "-" +
              mm +
              "-" +
              today.getFullYear() +
              "T" +
              today.getHours() +
              today.getMinutes();

            this.success = true;
            this.failed = false;
            const blob = new Blob([response.data], { type: "application/pdf" });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = today + "-download-list.pdf";
            link.click();
            URL.revokeObjectURL(link.href);
          })
          .catch((err) => {
            console.log(err);
            this.success = false;
            this.failed = true;
          });
      }
    },
  },
  watch: {
    location(location) {
      if (location !== null && location !== undefined) {
        this.setLocation(location);
        this.fetchItems(true);
      }
    },
  },
};
</script>

<style lang="css" scoped>
.row-pointer {
  cursor: pointer;
}
</style>
