var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"mx-auto",attrs:{"tile":"","loading":_vm.loading,"width":"500px"}},[_c('v-card-title',{staticClass:"header",attrs:{"dense":"","flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("printlist.form.title")))])],1),_c('v-card-text',[_c('v-form',{ref:"form"},[_c('v-row',[_c('v-col',{staticClass:"mx-auto pb-0 mt-3",attrs:{"cols":"10","width":"400px"}},[_c('v-select',{attrs:{"items":_vm.locations,"item-text":(item) => {
                return item.name + ' (' + item.code + ')';
              },"item-value":"code","dense":"","solo":"","return-object":"","single-line":"","rules":[
              (v) =>
                !!v ||
                _vm.$t('validation.required', {
                  prop: _vm.$tc('locations.title', 1),
                }),
            ],"hint":_vm.$t('locations.description'),"persistent-hint":""},on:{"input":_vm.selectLocation},model:{value:(_vm.selectedLocation),callback:function ($$v) {_vm.selectedLocation=$$v},expression:"selectedLocation"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","disabled":_vm.location === null,"loading":_vm.loading,"text":""},on:{"click":_vm.downloadList}},[_vm._v(_vm._s(_vm.$t("printlist.form.download"))+" ")])],1),_c('v-snackbar',{attrs:{"value":true,"bottom":"","tile":"","color":"success accent-2"},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.success = false}}},'v-btn',attrs,false),[_vm._v(" "+_vm._s(_vm.$t("printlist.snackbar.close"))+" ")])]}}]),model:{value:(_vm.success),callback:function ($$v) {_vm.success=$$v},expression:"success"}},[_vm._v(" "+_vm._s(_vm.$t("printlist.snackbar.success"))+" ")]),_c('v-snackbar',{attrs:{"value":true,"bottom":"","tile":"","color":"red accent-2"},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.failed = false}}},'v-btn',attrs,false),[_vm._v(" "+_vm._s(_vm.$t("printlist.snackbar.close"))+" ")])]}}]),model:{value:(_vm.failed),callback:function ($$v) {_vm.failed=$$v},expression:"failed"}},[_vm._v(" "+_vm._s(_vm.$t("printlist.snackbar.failed"))+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }