<template>
  <v-card tile :loading="loading" class="mx-auto" width="500px">
    <v-card-title dense flat class="header">
      <v-toolbar-title>{{ $t("printlist.form.title") }}</v-toolbar-title>
    </v-card-title>

    <v-card-text>
      <v-form ref="form">
        <v-row>
          <v-col cols="10" class="mx-auto pb-0 mt-3" width="400px">
            <v-select
              v-model="selectedLocation"
              :items="locations"
              :item-text="
                (item) => {
                  return item.name + ' (' + item.code + ')';
                }
              "
              item-value="code"
              dense
              solo
              return-object
              single-line
              :rules="[
                (v) =>
                  !!v ||
                  $t('validation.required', {
                    prop: $tc('locations.title', 1),
                  }),
              ]"
              @input="selectLocation"
              :hint="$t('locations.description')"
              persistent-hint
            ></v-select>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="blue darken-1"
        :disabled="location === null"
        :loading="loading"
        @click="downloadList"
        text
        >{{ $t("printlist.form.download") }}
      </v-btn>
    </v-card-actions>

    <v-snackbar
      v-model="success"
      :value="true"
      bottom
      tile
      color="success accent-2"
    >
      {{ $t("printlist.snackbar.success") }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="success = false">
          {{ $t("printlist.snackbar.close") }}
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar v-model="failed" :value="true" bottom tile color="red accent-2">
      {{ $t("printlist.snackbar.failed") }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="failed = false">
          {{ $t("printlist.snackbar.close") }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { DownloadService } from "@/services/DownloadService";

export default {
  name: "PrintListForm",
  data: () => ({
    selectedLocation: null,
    date: null,
    lists: [],
    success: false,
    failed: false,
  }),
  mounted() {
    if (localStorage.location) {
      this.selectedLocation = JSON.parse(localStorage.location);
      this.selectLocation(this.selectedLocation);
    }
  },
  computed: {
    ...mapGetters("auth", ["locations"]),
    ...mapState("locationsOverview", ["items", "loading", "location"]),
  },
  methods: {
    ...mapActions("locationsOverview", ["selectLocation"]),
    downloadList: function () {
      if (this.location !== null) {
        DownloadService.downloadPrintList(this.location.code)
          .then((response) => {
            let today = new Date();
            let dd = String(today.getDate()).padStart(2, "0");
            let mm = String(today.getMonth() + 1).padStart(2, "0");
            today =
              dd +
              "-" +
              mm +
              "-" +
              today.getFullYear() +
              "T" +
              today.getHours() +
              today.getMinutes();

            this.success = true;
            this.failed = false;
            const blob = new Blob([response.data], { type: "application/pdf" });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = today + "-download-list.pdf";
            link.click();
            URL.revokeObjectURL(link.href);
          })
          .catch((err) => {
            console.log(err);
            this.success = false;
            this.failed = true;
          });
      }
    },
  },
  watch: {
    location(location) {
      localStorage.location = JSON.stringify(location);
    },
  },
};
</script>
